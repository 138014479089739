import { Injectable } from '@angular/core';
import { Career } from '../models/career';
import careerJSON from '../data/careers.json'


@Injectable({
  providedIn: 'root'
})

export class CareerService {

  constructor() { }

  getcareerListing() {
    return careerJSON as Career[];
  }

}