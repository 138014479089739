import { Component, OnInit } from '@angular/core';
import { Career } from 'src/app/models/career';
import { CareerService } from 'src/app/services/career.service';
import { AppConstants } from 'src/app/utils/app-contants';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})

export class CareerComponent implements OnInit {

  careerListing: Career[] = [];
  expandedIndex = 0

  constructor(
    private careerService: CareerService,
    private appConstants:AppConstants,
    private route:Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    
    this.loadCareerData()
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params.hasOwnProperty('job_idx')){
        this.expandedIndex = Number(params.job_idx-1)
        this.scrollTo("job-"+(params.job_idx))
      }
      else{
        window.scrollTo(0, 0)
      }
      
      
    })
  }

  public onCareerItemClick(index: number) {
    this.expandedIndex = index;
    this.route.navigate([],{relativeTo:this.activatedRoute,queryParams:{'job_idx':index+1}})
    this.scrollTo("job-" + index+1);
  }

  public scrollTo(id: any): void {
    
    setTimeout(() => {
      let element = document.getElementById(id);
      const offset = 45;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
      });
    }, 1);
  }

  public formatRequirements(requirement: string): string[] {
    return requirement.split(this.appConstants.careerRequirementSplitCharactor);
  }

  private loadCareerData() {
    this.careerListing = this.careerService.getcareerListing();
  }

  


}