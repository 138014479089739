import { Component, OnInit, ViewChild } from "@angular/core";
import { DragScrollComponent } from "ngx-drag-scroll";
import { BlogService } from "src/app/services/blog.service";
import { CsrService } from "src/app/services/csr.service";
import { EventsService } from "src/app/services/events.service";

/**
 * Section Types available in the UI
 */
enum SectionType {
  CaseStudy,
  Events,
  EnganeCSR,
}

@Component({
  selector: "app-homefeatures",
  templateUrl: "./homefeatures.component.html",
  styleUrls: ["./homefeatures.component.scss"],
})
export class HomefeaturesComponent implements OnInit {
  sectionType = SectionType; // Store a reference to the enum
  activeSection: SectionType = SectionType.CaseStudy; //reference to active section
  blogList : Array<object>
  eventList : Array<object>
  csrList : Array<object>
  @ViewChild("tabsContentRef", { read: DragScrollComponent, static: true })
  ds: DragScrollComponent;

  constructor(
    private blog:BlogService,
    private event:EventsService,
    private csr:CsrService

  ) { }

  ngOnInit() {
    $(document).ready(function () {
      $(".main-nav a").click(function (e) {
        e.preventDefault();
        $(".main-nav li").removeClass("active");
        $(this).parents("li").addClass("active");
      });
    });

    this.blogList = this.blog.getBlogListing()
    this.eventList = this.event.getEventList()
    this.csrList = this.csr.csrListing()
  }

  /**
   * This will trigger when section titles are clicked
   * @param section : SectionType on which clicked
   */
  onClickSection(section: SectionType) {
    this.activeSection = section;
    this.resetScrollPosition();
  }

  /**
   * Check if the current section matches the activated section
   * @param section
   */
  isSectionActive(section: SectionType): boolean {
    return this.activeSection === section;
  }

  /**
   * Methods to reset scroll position to begin
   */
  resetScrollPosition() {
    setTimeout(() => {
      this.ds.moveTo(3);
    }, 50);
  }
  
  goToBlog(url: string) {
    window.open(url, '_blank'); // Opens the link in a new tab
  }

  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
    { img: "http://placehold.it/350x150/666666" },
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    variableWidth: true,
  };

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" });
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) { }

  breakpoint(e) { }

  afterChange(e) { }

  beforeChange(e) { }
}
