import { Injectable } from '@angular/core';
import blogJSON from '../data/blog.json'
import { blogs } from '../models/blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor() { }
  getBlogListing(){
    return blogJSON as blogs[];
  }
}
