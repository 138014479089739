import { Injectable } from '@angular/core';
import csrJSON from '../data/CSR.json'
import { CSR } from '../models/csr';
@Injectable({
  providedIn: 'root'
})
export class CsrService {

  constructor() { }
  csrListing(){
    return csrJSON as CSR[]
  }
}
