import { Injectable } from '@angular/core';
import eventJson from '../data/event.json'
import { events } from '../models/events';
@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor() { }
  getEventList(){
    return eventJson as events[]
  }
}
